import Phaser from 'phaser';
import { levels } from './levels.js';


class UpgradeScene extends Phaser.Scene {
    constructor() {
        super({ key: 'UpgradeScene' });
        this.dragStartY = 0; // Track the initial drag position
    }

    preload() {
        // Load assets
        this.load.image("navback", "/assets/navback.png");
        this.load.image("homenav", "/assets/homenav.png");
        this.load.image("upgradesnavactive", "/assets/upgradesnavavtive.png");
        this.load.image("groupsnav", "/assets/groupsnav.png");
        this.load.image("earnnav", "/assets/earnnav.png");
    }

    create() {
        const sizes = { width: 650, height: 1280 };

        // Background
        this.add.rectangle(0, 0, sizes.width, sizes.height, 0x111111).setOrigin(0);

        // Add header text at the top center
        this.add.text(sizes.width / 2, 50, "Levels Roadmap", {
            font: '36px Arial',
            fill: '#ffffff',
            fontStyle: 'bold',
        }).setOrigin(0.5);

        // Bottom navigation
        this.createBottomNavigation();

        // Scrollable container for levels
        this.createScrollableLevels();
    }

    createBottomNavigation() {
        const sizes = { width: 650, height: 1280 };

        // Add navigation background
        this.add.rectangle(0, sizes.height - 100, sizes.width, 100, 0x222222).setOrigin(0);

        // Add navigation buttons
        this.navBack = this.add.image(0, sizes.height - 100, "navback").setOrigin(0, 0).setDisplaySize(sizes.width, 100);
        this.navigationHome = this.add.image(30, sizes.height - 85, "homenav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationHome.setInteractive();
        this.navigationHome.on('pointerdown', () => this.transitionToScene('GameScene'));
        this.add.text(this.navigationHome.x - 15, this.navigationHome.y + this.navigationHome.displayHeight, "Exchange", { font: "20px helvetica bold", fill: "#9A9A9A" });

        this.navigationUpgrades = this.add.image(sizes.width / 3 - 10, sizes.height - 85, "upgradesnavactive").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationUpgrades.setInteractive();
        this.navigationUpgrades.on('pointerdown', () => this.transitionToScene('UpgradeScene'));
        this.add.text(this.navigationUpgrades.x - 15, this.navigationUpgrades.y + this.navigationUpgrades.displayHeight, "Upgrades", { font: "20px helvetica bold", fill: "#9A9A9A" });

        this.navigationReferral = this.add.image(sizes.width / 3 * 2 - 30, sizes.height - 85, "groupsnav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationReferral.setInteractive();
        this.navigationReferral.on('pointerdown', () => this.transitionToScene('EarnCoinsScene'));
        this.add.text(this.navigationReferral.x - 15, this.navigationReferral.y + this.navigationReferral.displayHeight, "Referral", { font: "20px helvetica bold", fill: "#9A9A9A" });

        this.navigationEarn = this.add.image(sizes.width - 90, sizes.height - 85, "earnnav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationEarn.setInteractive();
        this.navigationEarn.on('pointerdown', () => this.transitionToScene('ShareScene'))
        this.add.text(this.navigationEarn.x - 20, this.navigationEarn.y + this.navigationEarn.displayHeight, "Earn more", { font: "20px helvetica bold", fill: "#9A9A9A" });
    }

    createScrollableLevels() {
        const sizes = { width: 650, height: 1280 };

        // Mask for scrollable area
        const maskShape = this.add.graphics().fillStyle(0x000000).fillRect(0, 100, sizes.width, sizes.height - 200);

        // Create a container for level data
        const levelContainer = this.add.container(0, 100);

        // Iterate through the imported levels data
        let index = 0;

        for (const [levelNumber, levelData] of Object.entries(levels)) {
            const yPosition = index * 220; // Vertical spacing for each level

            // Create a circle for each level
            const circle = this.add.circle(sizes.width / 2, yPosition + 60, 100, 0x1e3654); // Pale blue
            const levelText = this.add.text(sizes.width / 2, yPosition + 60,
                `Level ${levelNumber}\nDrCoins: ${levelData.dogsNeeded}\nTap: ${levelData.perTap}\nHour: ${levelData.perHour}`,
                {
                    font: '22px Arial',
                    fill: '#ffffff',
                    align: 'center',
                }
            ).setOrigin(0.5);

            levelContainer.add(circle);
            levelContainer.add(levelText);
            index++;
        }

        // Set the container's size to reflect all items
        levelContainer.setSize(sizes.width, Object.keys(levels).length * 220);

        // Mask the container to ensure only visible elements are shown
        levelContainer.setMask(new Phaser.Display.Masks.GeometryMask(this, maskShape));

        // Add scrolling functionality
        const scrollZone = this.add.zone(0, 100, sizes.width, sizes.height - 200).setOrigin(0).setInteractive();
        this.input.setDraggable(scrollZone);

        let dragStartY = 0;
        this.input.on('dragstart', (pointer) => {
            dragStartY = pointer.y - levelContainer.y;
        });

        this.input.on('drag', (pointer) => {
            // Update container position during drag
            const newY = pointer.y - dragStartY;

            // Clamp the container within scrollable bounds
            levelContainer.y = Phaser.Math.Clamp(
                newY,
                sizes.height - 200 - levelContainer.height,
                100
            );
        });
    }



    transitionToScene(targetScene) {
        this.scene.start(targetScene);
    }
}

export default UpgradeScene;
