'use strict';
import Phaser from 'phaser';
import GameScene from './main';
import { userReferralCode } from './main.js';

const sizes = {
    width: 650,
    height: 1280
}


let referralLink = "";
let userId = "";
let botUsername = "";
var inviteText = "Invite up to 10 friends with your referal code,\n and each of you will earn 10 Dr. Dog Coins!"

function shareReferralLink() {
    if (navigator.share) {
        navigator.share({
            title: 'Invite to My Mini App',
            text: 'Join this mini app and earn rewards!',
            url: referralLink,
        })
            .then(() => console.log('Link shared successfully'))
            .catch((error) => console.error('Error sharing:', error));
    } else {
        // Fallback for browsers that do not support the share API
        navigator.clipboard.writeText(referralLink).then(() => {
            console.log("Link copied to clipboard!");
            alert("Link copied to clipboard! Share it with your friends.");
        }).catch(err => console.error('Error copying link: ', err));
    }
}

class EarnCoinsScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'EarnCoinsScene'
        });
        this.navigationHome;
        this.navigationShare;
        this.navigationGroups;
        this.inviteButton;
    }

    preload() {

        Telegram.WebApp.ready();
        try {
            userId = Telegram.WebApp.initDataUnsafe.user.id;
        }
        catch (error) {
            console.log(error);
        }
        botUsername = "fleicom_bot";

        // Generate the referral link
        referralLink = `https://t.me/${botUsername}?start=` + userReferralCode;

        this.load.image("homenav", "/assets/homenav.png");
        this.load.image("earnnav", "/assets/earnnav.png");
        this.load.image("groupsnav", "/assets/groupsnav.png");
        this.load.image("upgradesnav", "/assets/upgradesnav.png");
        this.load.image("homenavactive", "/assets/homenavactive.png");
        this.load.image("earnnavactive", "/assets/earnnavactive.png");
        this.load.image("groupsnavactive", "/assets/groupsnavactive.png");
        this.load.image("upgradesnavacitve", "/assets/upgradesnavavtive.png");
        this.load.image("invite", "/assets/invite.png");
    }

    create() {

        //add background
        this.add.image(0, 0, "back").setDisplaySize(sizes.width * 2, sizes.height * 2);

        //bottom nav buttons
        this.navBack = this.add.image(0, sizes.height - 100, "navback").setOrigin(0, 0).setDisplaySize(sizes.width, 100);
        this.navigationHome = this.add.image(30, sizes.height - 85, "homenav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationHome.setInteractive();
        this.navigationHome.on('pointerdown', () => this.transitionToScene('GameScene'));
        this.add.text(this.navigationHome.x - 15, this.navigationHome.y + this.navigationHome.displayHeight, "Exchange", { font: "20px helvetica bold", fill: "#9A9A9A" });

        this.navigationUpgrades = this.add.image(sizes.width / 3 - 10, sizes.height - 85, "upgradesnav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationUpgrades.setInteractive();
        this.navigationUpgrades.on('pointerdown', () => this.transitionToScene('UpgradeScene'));
        this.add.text(this.navigationUpgrades.x - 15, this.navigationUpgrades.y + this.navigationUpgrades.displayHeight, "Upgrades", { font: "20px helvetica bold", fill: "#9A9A9A" });

        this.navigationReferral = this.add.image(sizes.width / 3 * 2 - 30, sizes.height - 85, "groupsnavactive").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationReferral.setInteractive();
        this.navigationReferral.on('pointerdown', () => this.transitionToScene('EarnCoinsScene'));
        this.add.text(this.navigationReferral.x - 15, this.navigationReferral.y + this.navigationReferral.displayHeight, "Referral", { font: "20px helvetica bold", fill: "#9A9A9A" });

        this.navigationEarn = this.add.image(sizes.width - 90, sizes.height - 85, "earnnav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationEarn.setInteractive();
        this.navigationEarn.on('pointerdown', () => this.transitionToScene('ShareScene'))
        this.add.text(this.navigationEarn.x - 20, this.navigationEarn.y + this.navigationEarn.displayHeight, "Earn more", { font: "20px helvetica bold", fill: "#9A9A9A" });
        //  this.navigationShare.on('pointerdown', () => this.transitionToScene('AnotherScene'));
        this.cameras.main.fadeIn(1000, 0, 0, 0);

        // Center text "Other ways to earn free coins"
        this.textYourReferral = this.add.text(sizes.width / 2, 80, "How to earn more coins?", {
            font: "50px Arial Bold",
            fill: "#ffffff"
        }).setOrigin(0.5, 0.5);

        // Center invite text
        this.textYourReferral2 = this.add.text(sizes.width / 2, sizes.height / 2 - 300, inviteText, {
            font: "30px Arial Bold",
            fill: "#ffffff"
        }).setOrigin(0.5, 0.5);

        // Center invite button below text
        this.inviteButton = this.add.image(sizes.width / 2, this.textYourReferral2.y + 100, "invite")
            .setOrigin(0.5, 0.5)  // Center button
            .setDisplaySize(300, 80)
            .setInteractive();

        // Add button click event
        this.inviteButton.on('pointerdown', () => shareReferralLink());

        // Optional: Add text or additional effects in your scene

        this.events.on('shutdown', this.shutdown, this);
    }


    // Clean up the input when switching scenes or stopping the game
    shutdown() {
        // Check if the input field exists and remove it
        if (this.inputField) {
            this.inputField.remove();
            this.inputField = null;
            console.log("Input field removed on scene shutdown");
            6
        }
    }

    update(time, delta) {
        // Update logic here if needed
    }

    // Define the callback functions
    onDown() {
        this.scene.start('scene-game');
    }

    onUp() {
        console.log("Button released");
        // Add your logic for the button up event
    }

    onShareDown() {
        console.log("Share button pressed");
        // Add your logic for the share button down event
    }

    onShareUp() {
        console.log("Share button released");
        // Add your logic for the share button up event
    }

    // Function to handle scene transition with a fade effect
    transitionToScene(targetScene) {
        this.cameras.main.fadeOut(300, 0, 0, 0);  // Fade out over 1 second (1000ms)

        this.time.delayedCall(300, () => {  // Wait for fade-out to complete
            this.scene.start(targetScene);   // Start the new scene
        });
    }
}

export default EarnCoinsScene;
