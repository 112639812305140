'use strict';

// levels.js
export const levels = {
    1: {
        perTap: 1,
        perHour: 100,
        dogsNeeded: 1,
    },
    2: {
        perTap: 2,
        perHour: 500,
        dogsNeeded: 2,
    },
    3: {
        perTap: 4,
        perHour: 800,
        dogsNeeded: 6
    },
    4: {
        perTap: 4,
        perHour: 1000,
        dogsNeeded: 25,
    },
    5: {
        perTap: 5,
        perHour: 2000,
        dogsNeeded: 100,
    },
    6: {
        perTap: 6,
        perHour: 4000,
        dogsNeeded: 200,
    },
    7: {
        perTap: 8,
        perHour: 4000,
        dogsNeeded: 300,
    },
    8: {
        perTap: 10,
        perHour: 4000,
        dogsNeeded: 400,
    },
    9: {
        perTap: 10,
        perHour: 5000,
        dogsNeeded: 500,
    },
    10: {
        perTap: 12,
        perHour: 5000,
        dogsNeeded: 600,
    },
    11: {
        perTap: 12,
        perHour: 5000,
        dogsNeeded: 700,
    },
    12: {
        perTap: 15,
        perHour: 6000,
        dogsNeeded: 1000,
    },
    // Add more levels as needed
};

// Function to get level properties
function getLevelProperties(levelNumber) {
    return levels[levelNumber] || null; // Return null if the level doesn't exist
}

// Function to check for a new level and return the new level number
function checkForNewLevel(currentLevel, points, dogs) {
    let level = currentLevel;
    let levelData = getLevelProperties(level);

    if (levelData != null) {
        if (levelData.dogsNeeded <= dogs) {
            level = level + 1
        }
    }
    else {
        return 1;
    }

    return level; // Return the updated level
}


function canAddScore(perHour, maxPoints, points, last_seen, score_last_seen) {

    if (perHour > 0) {
        // Get the current time in milliseconds
        const currentTime = Date.now(); // Current time in milliseconds

        // Convert `last_seen` (assume it's a timestamp in milliseconds) to a Date object
        const lastSeenTime = new Date(last_seen).getTime(); // Convert to milliseconds

        // Calculate the difference in milliseconds
        const timeDifference = currentTime - lastSeenTime;

        // Check if the difference is greater than 60 minutes (60 * 60 * 1000 ms)
        const sixtyMinutesInMilliseconds = 60 * 60 * 1000;

        if (timeDifference > sixtyMinutesInMilliseconds) {
            perHour = maxPoints
            return true; // Can add score
        } else {
            return false; // Cannot add score
        }
    }
    else {
        if (perHour > 0) {
            return true
        }
        else {
            return false
        }
    }
}

async function getUserLastActivity(mainApiURL, userId) {
    try {
        // Prepare the API URL
        const apiUrl = `${mainApiURL}?action=getScoreLastSeen&userID=${encodeURIComponent(userId)}`;
        console.log('Fetching URL:', apiUrl);

        // Make the fetch request
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', // Required for JSON responses
            },
        });

        console.log('Response status:', response.status);
        console.log('Response ok:', response.ok);

        // Check if the response is successful
        if (!response.ok) {
            console.error('HTTP Error:', response.status, response.statusText);
            throw new Error(`HTTP error: ${response.status}`);
        }

        // Parse the JSON response
        const data = await response.json();
        console.log('Response JSON:', data);

        // Ensure the data has the required fields
        if (!data || typeof data !== 'object') {
            console.error('Unexpected response format:', data);
            throw new Error('Invalid response format');
        }

        return {
            score_last_seen: data.score_last_seen || 0,
            last_seen: data.last_seen || 'unknown',
        };
    } catch (error) {
        console.error('Fetch operation failed:', error.message);
        console.error('Error details:', error);
        return { score_last_seen: 0, last_seen: 'unknown' }; // Default fallback
    }
}

export { getLevelProperties, checkForNewLevel, getUserLastActivity, canAddScore };
