'use strict';
import './style.css'
import Phaser from 'phaser'
import ShareScene from './shareScene';
import EarnCoinsScene from './earnCoinsScene'
import UpgradeScene from './upgradeScene'
import { getLevelProperties, checkForNewLevel, getUserLastActivity, canAddScore } from './levels.js';

const sizes = {
  width: 650,
  height: 1280,
}

const speedDown = 100;
const coinsToDog = 100;

const topSizeAnimation = 0.2;
let topReached = false;
const bottomSizeAnimation = 0.1;

const rewardForReferral = 10

var name = "";
var last_name = "";
var user_name = "";
var userId = "";
var mainApiURL = "";
var points = 0;
var totalClicksPerSession = 1;
var perHourMaxedTime = 0
export var userReferralCode = ""
var inviteReferralCode = ""
var invitingUserID = ""
var showPopupTrigger = false

// if referral was used refNotify will get value which represents a reward. It is used only for showing notification.
// After that, it should be cleard in database - using api function clearRefNotify
var refNotify = ""

var dogsCoins = 0;
var perTap = 1;
var perHour = 1;
var perHourEmpty = false;
var level = 1;
var score_last_seen = 0;
var mainApiURL = "https://drdog.fleicom.com/api/api.php"
var popup

var levelData = getLevelProperties(level);


// Get user's data from database: Score, perHour, perTap, dogCoins, level
async function getUserDataNew() {

  const url = `${mainApiURL}?action=getUser&userID=${encodeURIComponent(userId)}`;
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch player data: ${response.statusText}`);
    }

    const data = await response.json();
    if (data.error) {
      if (data.error != "User not found")
        throw new Error(data.error);
    }

    // Extract score, perhour, and pertap from the response
    const scoreDB = data.score || 0;
    const perhourDB = data.perhour || 0;
    const pertapDB = data.pertap || 0;
    const dogCoinDB = data.dog_coin || 0;
    const levelDB = data.level || 1;
    const referralDB = data.referral || "";
    const refNot = data.refNotify || "";

    userReferralCode = referralDB

    perHourMaxedTime = data.tapPerHourMaxed || -1;

    // check if new user, data not found in database
    if (perTap == 0) {
      return { score: 0, perHour: levelData.perHour, perTap: levelData.perTap, dogCoin: 0, level: 1 };
    }

    console.log(`User's score is: ${scoreDB}`);
    console.log(`User's per hour value is: ${perhourDB}`);
    console.log(`User's per tap value is: ${pertapDB}`);
    console.log(`User's dog coin value is: ${dogCoinDB}`);

    //Insert user data into database
    await updateUser(userId, name, last_name, user_name, scoreDB);

    if (inviteReferralCode != null) {
      // get inviting userID, it is needed in "doReferralStuff(), then if
      // succesful sinside is call doReferralStuf()"
      invitingUserID = await getUserByReferral()
    }

    if (refNot != "") {
      // if referral was used by a friend, it will show notification. This will clear notification, so it won't
      // show up next time
      clearReferralNotify()

      // Show reward pop-up
      showPopupTrigger = true
    }

    // Return all values as an object
    return { scoreDB, perhourDB, pertapDB, dogCoinDB, levelDB, perHourMaxedTime, referralDB, refNot };

  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);

    // Return default values in case of an error
    return { scoreDB: 0, perhourDB: 0, pertapDB: 0, dogCoinDB: 0 };
  }
}


// Insert user's data into database
async function updateUser(userId, name, last_name, user_name, score_last_seen) {

  const apiUrl = `${mainApiURL}?action=updateUser`;

  const data = {
    userID: userId,
    name: name,
    last_name: last_name,
    user_name: user_name,
    score_last_seen: score_last_seen
  };

  fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      // Check if the response is OK (status 200)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the JSON response
    })
    .then(data => {
      console.log('Success:', data); // Handle success
    })
    .catch(error => {
      console.error('Error:', error); // Handle errors
    });
}


// Add data to database. It adds userID, invitedUserId, refferal code
async function doReferralStuff() {
  console.log('Referral in action... CODE: ' + inviteReferralCode);

  let apiUrl = `${mainApiURL}?action=addReferral`;

  let data = {
    userID: userId,
    invitingUserID: invitingUserID,
    referral: inviteReferralCode
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status}`);
    }

    const responseData = await response.json();
    console.log('Success:', responseData);
  } catch (error) {
    console.error('Error:', error);
  }

  //increase scores for both users
  let apiUrl2 = `${mainApiURL}?action=updateDogCoinForUsers`;

  let data2 = {
    userID: userId,
    userID2: invitingUserID,
    increment: rewardForReferral
  };

  try {
    const response = await fetch(apiUrl2, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data2),
    });

    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status}`);
    }

    const responseData = await response.json();
    console.log('Success:', responseData);
  } catch (error) {
    console.error('Error:', error);
  }


}

// clear referral notification
function clearReferralNotify() {
  fetch(`${mainApiURL}?action=clearRefNotify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      userID: userId
    })
  })
    .then(response => response.json())
    .then(data => console.log("Update Score Response:", data))
    .catch(error => console.error("Error:", error));
}

// Returns inviting user ID based on referral code
async function getUserByReferral() {
  const apiUrl = `${mainApiURL}?action=getUserByReferral&referral=${inviteReferralCode}`;
  console.log('Fetching URL:', apiUrl);
  let response = "";

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Response JSON:', data);

    // ✅ Ensure data is valid before accessing properties
    if (!data) {
      console.error('Invalid or missing userID in response:', data);
      return { response: 'unknown' }; // Fallback value
    }
    else {
      // If there was no error, do referral stuff
      invitingUserID = data
      await doReferralStuff()
    }

    return { response: data };

  } catch (error) {
    console.error('Fetch operation failed:', error);
    return { response: 'unknown' };
  }
}

// Get parameters from URL and save them into variables userName and userId
function getQueryParams() {

  const params = {};
  const queryString = window.location.search;  // Get the part of the URL after "?"

  console.log("Query string:", queryString);  // Debugging: Check what the query string looks like

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);  // Create URLSearchParams object from query string

    // Iterate over each parameter and store it in the params object
    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
      console.log(`Parameter found - ${key}: ${value}`);  // Debugging: Log each key-value pair
    }
  } else {
    console.log("No query parameters found.");
  }
  userId = params["userID"]
  name = params["name"];
  last_name = params["last_name"]
  user_name = params["user_name"]
  inviteReferralCode = params["referral"]


  console.log("Referral code:", inviteReferralCode);

  if (user_name == null) {
    user_name = "not_available"
  }

  if (last_name == null) {
    last_name = "not_available"
  }

  if (window.Telegram && window.Telegram.WebApp) {
    let initData = Telegram.WebApp.initDataUnsafe;
    console.log(initData);
  }

  return params;

}


class BasicButton extends Phaser.GameObjects.Sprite {

  constructor(config) {

    //check if config contains a scene
    if (!config.scene) {
      console.log('missing scene');
      return;
    }
    //check if config contains a key
    if (!config.key) {
      console.log("missing key!");
      return;
    }
    //if there is no up property assume 0
    if (!config.up) {
      config.up = 0;
    }
    //if there is no down in config use up
    if (!config.down) {
      config.down = config.up;
    }
    //if there is no over in config use up
    if (!config.over) {
      config.over = config.up;
    }
    //call the constructor of the parent
    //set at 0,0 in case there is no x and y
    //in the config
    super(config.scene, 0, 0, config.key, config.up);

    //make a class level reference to the config
    this.config = config;


    //if there is an x assign it
    if (config.x) {
      this.x = config.x;
    }
    //if there is an x assign it
    if (config.y) {
      this.y = config.y;
    }

    if (config.width && config.height) {
      this.setDisplaySize(config.width, config.height);
    }
    //
    //add this to the scene
    config.scene.add.existing(this);
    //
    //make interactive and set listeners
    this.setInteractive();
    this.on('pointerdown', this.onDown, this);
    this.on('pointerup', this.onUp, this);
    this.on('pointerover', this.onOver, this);
    this.on('pointerout', this.onUp, this);
  }
  onDown() {
    this.setFrame(this.config.down);
  }
  onOver() {
    this.setFrame(this.config.over);
  }
  onUp() {
    this.setFrame(this.config.up);
  }
}


// Scene ****
class GameScene extends Phaser.Scene {

  constructor() {
    super({
      key: 'GameScene'
    });
    this.player
    this.playerPressed
    this.background;

    this.textScore;

    this.textTokens;
    this.textTokensTokens;
    this.textPerTap;
    this.textPerTapPerTap;
    this.textPerHour;
    this.textPerHourPerHour;

    this.textRemaining;

    this.username;
    this.levelText;
    this.dropSound;

    this.levelAnnouncement;
    this.glow;
    this.emitter;
    this.dogCoinEmitter;
    this.button;
    this.buttonBack;
    this.buttonBack2;
    this.squareButton;
    this.squareTokenBack;
    this.squarePerTapBack;
    this.squarePerHourBack;
    this.bitcoin;
    this.bitcoinTap;
    this.bitcoinHour;
    this.dogPressed;
    this.dogCoin;


    this.lightEffect;
    this.navBack;
    this.navigationHome;
    this.navigationReferral;
    this.navigationUpgrades;
    this.navigationEarn;


  }

  // Load assets
  preload() {

    getQueryParams();
    this.load.image("back", "/assets/back.png");
    this.load.image("test", "/assets/dog.png");
    this.load.image("ball", "/assets/ball.png");
    this.load.image("bitcoin", "/assets/bitcoin.png");
    this.load.image("backbutton", "/assets/buttonback.png");
    this.load.image("dogPressed", "/assets/dogpressed.png");
    this.load.image("homenav", "/assets/homenav.png");
    this.load.image("earnnav", "/assets/earnnav.png");
    this.load.image("groupsnav", "/assets/groupsnav.png");
    this.load.image("upgradesnav", "/assets/upgradesnav.png");
    this.load.image("homenavactive", "/assets/homenavactive.png");
    this.load.image("earnnavactive", "/assets/earnnavactive.png");
    this.load.image("groupsnavacitve", "/assets/groupsnavactive.png");
    this.load.image("upgradesnavacitve", "/assets/upgradesnavavtive.png");
    this.load.image("lightEffect", "/assets/lighteffect.png");
    this.load.image("bitcoinLite", "/assets/coinlight.png");
    this.load.audio("drop", "/assets/sounds/drop.wav");
    this.load.image("dogCoin", "/assets/dogcoin.png");
    this.load.image("glow", "/assets/glow.png")
    this.load.image("square2", "/assets/square2.png")
    this.load.image("plus1", "/assets/plus1.png")
    this.load.image("navback", "/assets/navback.png")
  }

  async create() {


    this.dropSound = this.sound.add("drop");

    //light effect
    this.lightEffect = this.add.image(0, 75, "lightEffect").setOrigin(0, 0).setDisplaySize(sizes.width, 60);
    this.lightEffect = this.add.image(0, sizes.height - 130, "lightEffect").setOrigin(0, 0).setDisplaySize(sizes.width, 10);

    this.background = this.add.image(0, -205, "back").setDisplaySize(sizes.width, sizes.height + 200).setOrigin(0, 0);

    this.player = this.physics.add.image(sizes.width / 2 - 200, sizes.height / 2 - 50, "test").setOrigin(0, 0);
    this.player.setInteractive();
    this.player.on('pointerdown', this.onDown, this);
    this.player.on('pointerup', this.onUp, this);

    // Add initial invisible to visible animation
    this.tweens.add({
      targets: this.player,
      alpha: { from: 0, to: 1 },
      ease: 'Sine.InOut',
      duration: 5000,
      repeat: 0,
      yoyo: false
    });

    this.player.setDisplaySize(400, 400).setOffset(0, 0);
    this.player.setImmovable = true;
    this.player.body.allowGravity = false;
    this.player.setCollideWorldBounds(true);

    this.playerPressed = this.physics.add.image(sizes.width / 2 - 200, sizes.height / 2 - 50, "dogPressed").setOrigin(0, 0);
    this.playerPressed.setInteractive();
    this.playerPressed.on('pointerdown', this.onDown, this);
    this.playerPressed.on('pointerup', this.onUp, this);
    this.playerPressed.removeFromDisplayList();

    this.playerPressed.setDisplaySize(400, 400).setOffset(0, 0);
    this.playerPressed.setImmovable = true;
    this.playerPressed.body.allowGravity = false;
    this.playerPressed.setCollideWorldBounds(true);

    this.squareTokenBack = this.add.image(120, 180, "square2").setDisplaySize(180, 150);
    this.squarePerTapBack = this.add.image(120 + this.squareTokenBack.displayWidth + 22, 180, "square2").setDisplaySize(this.squareTokenBack.displayWidth, this.squareTokenBack.displayHeight);
    this.squarePerHourBack = this.add.image(120 + this.squareTokenBack.displayWidth * 2 + 20 * 2, 180, "square2").setDisplaySize(this.squareTokenBack.displayWidth, this.squareTokenBack.displayHeight);
    this.bitcoin = this.add.image(sizes.width / 2 - 70, 350, "bitcoin").setDisplaySize(60, 60);
    this.bitcoinTap = this.add.image(sizes.width / 2 - 120, 350, "bitcoin").setDisplaySize(60, 60);
    this.bitcoinHour = this.add.image(sizes.width / 2 - 100, 350, "bitcoin").setDisplaySize(60, 60);

    //dog coin gloww
    this.dogCoin = this.add.image(this.bitcoin.x, this.bitcoin.y + 100, "dogCoin").setDisplaySize(80, 80);

    this.bitcoin.setImmovable = true;
    this.dogCoin.setImmovable = true;
    this.dogCoin.setInteractive()
    this.dogCoin.on('pointerdown', this.onDogCoinDown, this);

    //bottom nav buttons
    this.navBack = this.add.image(0, sizes.height - 100, "navback").setOrigin(0, 0).setDisplaySize(sizes.width, 100);
    this.navigationHome = this.add.image(30, sizes.height - 85, "homenavactive").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationHome.setInteractive();
    this.navigationHome.on('pointerdown', () => this.transitionToScene('GameScene'));
    this.add.text(this.navigationHome.x - 15, this.navigationHome.y + this.navigationHome.displayHeight, "Exchange", { font: "20px helvetica bold", fill: "#9A9A9A" });
    5
    this.navigationUpgrades = this.add.image(sizes.width / 3 - 10, sizes.height - 85, "upgradesnav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationUpgrades.setInteractive();
    this.navigationUpgrades.on('pointerdown', () => this.transitionToScene('UpgradeScene'));
    this.add.text(this.navigationUpgrades.x - 15, this.navigationUpgrades.y + this.navigationUpgrades.displayHeight, "Upgrades", { font: "20px helvetica bold", fill: "#9A9A9A" });

    this.navigationReferral = this.add.image(sizes.width / 3 * 2 - 30, sizes.height - 85, "groupsnav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationReferral.setInteractive();
    this.navigationReferral.on('pointerdown', () => this.transitionToScene('EarnCoinsScene'));
    this.add.text(this.navigationReferral.x - 15, this.navigationReferral.y + this.navigationReferral.displayHeight, "Referral", { font: "20px helvetica bold", fill: "#9A9A9A" });

    this.navigationEarn = this.add.image(sizes.width - 90, sizes.height - 85, "earnnav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationEarn.setInteractive();
    this.navigationEarn.on('pointerdown', () => this.transitionToScene('ShareScene'))
    this.add.text(this.navigationEarn.x - 20, this.navigationEarn.y + this.navigationEarn.displayHeight, "Earn more", { font: "20px helvetica bold", fill: "#9A9A9A" });

    // text remaining text
    this.textRemaining = this.add.text(sizes.width / 2 - 110, 520, coinsToDog - parseInt(points) + " coins needed!", { font: "25px helvetica bold", fill: "#ffffff" });

    // Score text
    this.textScore = this.add.text(sizes.width / 2 - 20, this.bitcoin.y - 35, points, { font: "70px helvetica bold", fill: "#ffffff" });

    // text in top 3 square backgrounds
    this.textTokens = this.add.text(this.squareTokenBack.x - 15, this.squareTokenBack.y, dogsCoins, { font: "35px helvetica bold", fill: "#ffffff" });
    this.textTokensTokens = this.add.text(this.squareTokenBack.x - 35, this.squareTokenBack.y - 60, "Tokens", { font: "25px helvetica bold", fill: "#ffffff" });
    this.textPerTap = this.add.text(this.squarePerTapBack.x - 10, this.squarePerTapBack.y, "+" + perTap, { font: "35px helvetica bold", fill: "#ffffff" });
    this.textPerTapPerTap = this.add.text(this.squarePerTapBack.x - 35, this.squarePerTapBack.y - 60, "Per tap", { font: "25px helvetica bold", fill: "#ffffff" });
    this.textPerHour = this.add.text(this.squarePerHourBack.x - 10, this.squarePerHourBack.y, "+" + perHour, { font: "35px helvetica bold", fill: "#ffffff" });
    this.textPerHourPerHour = this.add.text(this.squarePerHourBack.x - 35, this.squarePerHourBack.y - 60, "Per hour", { font: "25px helvetica bold", fill: "#ffffff" });

    this.dogCoin.x = this.squareTokenBack.x - 35 - this.textTokens.displayWidth / 2
    this.dogCoin.y = this.squareTokenBack.y + 20;
    this.dogCoin.setDisplaySize(40, 40);

    this.bitcoinTap.x = this.squarePerTapBack.x - this.textPerTap.displayWidth / 2 - 20
    this.bitcoinTap.y = this.squarePerTapBack.y + 20;
    this.bitcoinTap.setDisplaySize(40, 40);

    this.bitcoinHour.x = this.squarePerHourBack.x - this.textPerHour.displayWidth / 2 - 20
    this.bitcoinHour.y = this.squarePerHourBack.y + 20;
    this.bitcoinHour.setDisplaySize(40, 40);

    //gdd glow
    this.glow = this.add.image(this.dogCoin.x, this.dogCoin.y, "glow").setOrigin(0.5, 0.5).setDisplaySize(200, 200).setVisible(false)
    this.glow.setBelow(this.dogCoin);

    //set lighteffect behind backgroudn
    this.lightEffect.setBelow(this.background)

    // Score text
    this.levelAnnouncement = this.add.text(-600, 450, " LEVEL " + level, { font: "150px helvetica bold", fill: "#ffffff" });

    //move coin in case of large number
    this.bitcoin.x = this.bitcoin.x - this.textScore.scaleX
    // userName text
    var un = ""
    if (name != "NaN") {
      un = name;
    }
    if (last_name != "NaN" && last_name != "not_available") {
      un = un + " " + last_name;
    }
    this.username = this.add.text(30, 35, un, { font: "25px helvetica bold", fill: "#ffffff" });
    this.levelText = this.add.text(sizes.width / 2 - 20, 35, "Level: " + level, { font: "25px helvetica bold", fill: "#ffffff" });


    this.emitter = this.add.particles(0, 0, "plus1",
      {
        speed: 200,
        gravity: 0,
        scale: { start: 1.2, end: 0 }, end: 0,
        duration: 200,
        lifespan: 2000,
        emitting: true,
        follow: this.player


      })
    this.emitter.setPosition(this.player.x + 100, this.player.y - 380);



    //emitter on converting coins to dr. dog
    this.dogCoinEmitter = this.add.particles(100, 100, "dogCoin",
      {
        speed: 200,
        gravity: speedDown - 500,
        scale: { start: 0.3, end: 0 }, end: 0,
        duration: 300,
        lifespan: 800,
        emitting: true,

      })

    this.dogCoinEmitter.setPosition(this.dogCoin.x, this.dogCoin.y);



    window.Telegram.WebApp.expand()


    const data = await getUserDataNew();
    const dataLastActivity = await getUserLastActivity(mainApiURL, userId);

    level = data.levelDB
    levelData = getLevelProperties(level);



    if (perHourMaxedTime != -1) {

      if (this.isMoreThanSixtyMinutes(perHourMaxedTime) == false) {
        perHour = 0;
        // so the time flag isnt set again on start
        perHourEmpty = true
      }
      else {
        perHour = levelData.perHour
      }
    }

    points = data.scoreDB
    dogsCoins = data.dogCoinDB
    perTap = data.pertapDB
    refNotify = data.refNot


    score_last_seen = dataLastActivity.score_last_seen;


    this.onDown()
    this.cameras.main.fadeIn(1000, 0, 0, 0);

  }


  // Main game logic, animation..., called all the time
  update() {

    this.textPerTap.text = "+" + perTap
    this.textPerHour.text = "+" + perHour
    level = checkForNewLevel(level, points, dogsCoins)
    this.levelText.text = "Level:" + level
    this.levelText.x = sizes.width - this.levelText.displayWidth - 10


    this.player.setBelow(this.emitter);
    this.playerPressed.setBelow(this.emitter);
    this.emitter.setAlpha(0.5)

    this.textScore.setText(points);

    this.textTokens.setText(dogsCoins);

    if (coinsToDog - points > 0) {
      this.textRemaining.setText(coinsToDog - points + " coins needed!")
    }
    else {
      this.textRemaining.setText("Convert coins to Dr. Dog!")
    }

    // animate new level
    // do animation until finished
    this.doLevelAnnouncement();

    //if ran out of perHour taps color it red...
    if (perHour <= 0) {
      this.textPerHour.setStyle({ color: '#ff0000' });
    }
    else {
      this.textPerHour.setStyle({ color: '#ffffff' });
    }


    if (points >= coinsToDog) {

      //glow rotation
      this.glow.angle++
      //dogCoin resizing animation
      this.resizingAnimation(this.dogCoin)

      //add glow
      this.tweens.add({
        targets: this.glow,
        alpha: { from: 0, to: 1 },
        ease: 'Sine.InOut',
        duration: 2000,
        repeat: 0,
        yoyo: false
      });

      this.glow.setVisible(true)

    }

    // Check for reward notifications...
    if (showPopupTrigger == true) {
      this.showPopup(this)
      showPopupTrigger = false
    }

  }




  doLevelAnnouncement() {
    if (!this.hasLevelAnnoucementAnimationFinished) {
      this.levelAnnouncement.setText("LEVEL " + level)
      // Increment the scale
      this.levelAnnouncement.x = this.levelAnnouncement.x + 6

      // Check if the scale has exceeded the threshold
      if (this.levelAnnouncement.x > sizes.width + 500) {
        this.hasLevelAnnoucementAnimationFinished = true;
      }
    } else {
      // Reset position 
      this.levelAnnouncement.x = - 700

    }
  }

  showPopup(scene) {
    // Prevent creating multiple popups at once by destroying the existing one (if any)
    if (popup) {
      popup.destroy();
    }

    // Calculate center of the screen based on the game's width and height
    var centerX = scene.cameras.main.centerX;
    var centerY = scene.cameras.main.centerY;

    // Create the popup container at the center of the screen
    popup = scene.add.container(centerX, centerY);  // Position it at the center

    // Background of the popup with light grey-blue color
    var background = scene.add.graphics();
    background.fillStyle(0xA9C7D7, 0.9); // Light grey-blue background
    background.fillRoundedRect(-160, -120, 320, 240, 20); // Rounded corners for the background
    popup.add(background);

    // Border/frame around the popup
    var border = scene.add.graphics();
    border.lineStyle(4, 0x3B7F94); // Darker blue border color
    border.strokeRoundedRect(-160, -120, 320, 240, 20); // Rounded corners for the border
    popup.add(border);

    // Text for "REFERRAL REWARD!" with a larger, bolder font, dark gold color, and shadow
    var title = scene.add.text(0, -80, 'REFERRAL REWARD!', {
      font: '28px "Verdana", sans-serif', // Larger font for the title
      color: '#FFD700', // Darker gold color (#8B6A3B)
      fontStyle: 'bold',
      align: 'center',
      shadow: {
        offsetX: 3,
        offsetY: 3,
        color: '#333333', // Dark shadow color
        blur: 5,
        stroke: false,
        fill: true
      }
    }).setOrigin(0.5);

    // Get text bounds for title and adjust its position
    var titleBounds = title.getBounds();
    title.setY(-120 + (150 - titleBounds.height) / 4); // Adjust the position for vertical centering

    popup.add(title);

    // Text for the notification message with a nice font and centered
    var message = scene.add.text(0, -40, '10 coins have been added to you!', {
      font: '24px "Verdana", sans-serif', // Nice font
      color: '#171a2b', // Black color for the message
      align: 'center',
      wordWrap: { width: 280, useAdvancedWrap: true } // Wrap the text if it's too long
    }).setOrigin(0.5);

    // Get text bounds and adjust position if needed
    var textBounds = message.getBounds();
    message.setY(-120 + (240 - textBounds.height) / 2); // Vertically center the text inside the background

    popup.add(message);

    // Close button with a nice font and hover effect
    var closeButton = scene.add.text(0, 100, 'Close', {
      font: '18px "Verdana", sans-serif',
      color: '#ffffff',
      backgroundColor: '#3B7F94',
      padding: { x: 10, y: 5 },
      borderRadius: 5,
      align: 'center'
    }).setOrigin(0.5);

    closeButton.setInteractive();
    closeButton.on('pointerdown', () => {
      popup.destroy();  // Close the popup
    });

    // Add hover effect for the close button
    closeButton.on('pointerover', () => {
      closeButton.setStyle({ color: '#ffeb3b' }); // Yellow text on hover
    });

    closeButton.on('pointerout', () => {
      closeButton.setStyle({ color: '#ffffff' }); // Reset color on mouse out
    });

    popup.add(closeButton);


  }

  resizingAnimation(image) {

    // Use a small value to check scale range (for floating-point precision)
    const precision = 0.0001;

    // Check if we should scale up or down based on the `topReached` status
    if (!topReached) {
      image.setScale(image.scale + 0.001);
      // If we reach or exceed the top size, toggle direction
      if (image.scale >= topSizeAnimation - precision) {
        topReached = true;
      }
    } else {
      image.setScale(image.scale - 0.0008);
      // If we reach or go below the bottom size, toggle direction
      if (image.scale <= bottomSizeAnimation + precision) {
        topReached = false;
      }
    }

  }


  //Convert coins to Dr Dog
  onDogCoinDown(sprite, pointer) {

    if (points >= coinsToDog) {
      // start emmiter effect
      this.dogCoinEmitter.start()

      //update drDog coins in database
      this.incrementDogCoin()

      //substract 1000 coins in database
      this.decreaseScoreAfterDogCoin()

      points = points - coinsToDog
      dogsCoins = dogsCoins + 1

      //check for new level
      let newLevel = checkForNewLevel(level, points, dogsCoins)

      if (newLevel > level) {
        level = newLevel
        totalClicksPerSession = 0;
        levelData = getLevelProperties(level)
        perTap = levelData.perTap

        // allow tapping even taps ran out, but only because level has increased
        perHour = levelData.perHour

      }

      // trigger animation
      if (newLevel != level) {
        hasLevelAnnoucementAnimationFinished = false;
      }

      //hide glow
      this.tweens.add({
        targets: this.glow,
        alpha: { from: 1, to: 0 },
        ease: 'Sine.InOut',
        duration: 3000,
        repeat: 0,
        yoyo: false
      });

    }
  }

  //* >>>  BUTTON EVENTS <<< */
  // On main button press. Here is main logic about calculating points, time limits, score limit
  onDown() {

    // Only first time...set time flag
    // TODO - IF LAST PRESS WAS AN HOUR AGO, RELOAD SERVER DATA!
    if (perHour == 0 && perHourEmpty == false) {

      //set time flag on server
      perHourEmpty = true
      this.setPerHourMaxed();
      // perHourMaxedTime = new Date(perHourMaxedTime).getTime()   REPLACE WITH NOW!!!!!
      perHourMaxedTime = Date.now();
    }

    //Do always while perHour ==0
    if (perHour == 0) {
      if (this.isMoreThanSixtyMinutes(perHourMaxedTime) == true) {
        perHour = levelData.perHour
        perHourEmpty = false;
      }
    }

    if (perHour < 0) {
      //set color to red if below 0 or 0
      this.textPerHour.setStyle({ color: '#ff0000' });
      perHourEmpty = true
      perHour = 0;
      // set time flag on server of time when perHour run out...
      this.setTimeFlagNow()
      this.setPerHourMaxed();
      perHourMaxedTime = new Date(perHourMaxedTime).getTime()
    }

    //if I can add score
    else if (perHour > 0) {
      perHour = score_last_seen + levelData.perHour - (totalClicksPerSession + score_last_seen)
      points = points + perTap;
      totalClicksPerSession = totalClicksPerSession + perTap;

      this.player.removeFromDisplayList();
      this.playerPressed.addToDisplayList();
      this.emitter.start();


      // perHour = levelData.perHour - (points - score_last_seen)
      if (perHour < 0) {
        perHour = 0;
        this.setPerHourMaxed()
      }

      perTap = levelData.perTap
      this.updateScore(perTap, level)
      //runUrlSilently(apiUrlScore + "&scoreIncrement=" + perTap + "&level=" + level);
    }

  }

  // on main button release
  onUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }

  //bottom navigation button, invite 
  onEarnDown(sprite, pointer) {
    this.scene.start('EarnCoinsScene');
  }
  onEarnUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }


  onShareDown(sprite, pointer) {
    // Switch to ShareScene
    this.scene.start('ShareScene');
  }
  onShareUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }
  //* >>> END OF BUTTON EVENTS <<< */

  // Set a time stamp of a user reaching hour limit in a database
  setTimeFlagNow() {
    fetch(`${mainApiURL}?action=setTimeFlagNow`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: userId,
      })
    })
      .then(response => response.json())
      .then(data => console.log("Update Score Response:", data))
      .catch(error => console.error("Error:", error));
  }

  // Set a time stamp of a user reaching hour limit in a database
  setPerHourMaxed() {
    fetch(`${mainApiURL}?action=setPerHourMaxed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: userId,
      })
    })
      .then(response => response.json())
      .then(data => console.log("Update Score Response:", data))
      .catch(error => console.error("Error:", error));
  }

  // Update user's score in database
  updateScore(perTap, level) {
    fetch(`${mainApiURL}?action=updateScore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: userId,
        scoreIncrement: perTap,
        level: level
      })
    })
      .then(response => response.json())
      .then(data => console.log("Update Score Response:", data))
      .catch(error => console.error("Error:", error));
  }

  // Decrease points after converted to dogCoins
  decreaseScoreAfterDogCoin() {

    fetch(`${mainApiURL}?action=decreaseScore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: userId,
        scoreIncrement: coinsToDog
      })
    })
      .then(response => response.json())
      .then(data => console.log("Update Score Response:", data))
      .catch(error => console.error("Error:", error));
  }

  // Incremenets number of dogCoins in database
  incrementDogCoin() {
    fetch(`${mainApiURL}?action=incrementDogCoin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: userId
      })
    })
      .then(response => response.json())
      .then(data => console.log("Update Score Response:", data))
      .catch(error => console.error("Error:", error));
  }


  // Function checks if it is more than 60 minutes since tapPerHourMaxtedTime
  isMoreThanSixtyMinutes(tapPerHourMaxedTime) {
    if (tapPerHourMaxedTime != -1) {
      const sixtyMinutesInMilliseconds = 60 * 60 * 1000;
      let now = Date.now();

      // Parse the input time and convert to a valid ISO 8601 format (UTC)
      const formattedTime = tapPerHourMaxedTime;
      const maxedTime = new Date(formattedTime).getTime();

      if (isNaN(maxedTime)) {
        console.error("Invalid date format:", tapPerHourMaxedTime);
        return false;
      }

      // Check if the difference is greater than 60 minutes
      if (now - maxedTime > sixtyMinutesInMilliseconds) {
        return true; // Can add score
      } else {
        return false; // Cannot add score
      }
    }
    else {
      return false
    }
  }

  // Get reandom X
  getRandomX() {
    return Math.floor(Math.random() * sizes.width);
  }

  gameOver() {
    console.log("Game over!");
  }

  // Nicer transition when moving to other scenes
  transitionToScene(targetScene) {
    this.cameras.main.fadeOut(300, 0, 0, 0);  // Fade out over 1 second (1000ms)

    this.time.delayedCall(300, () => {  // Wait for fade-out to complete
      this.scene.start(targetScene);   // Start the new scene
    });
  }
}


// Phaser main settings
const Config = {

  type: Phaser.WEBGL,
  width: sizes.width,
  height: sizes.height,
  scale: {
    // Fit to window
    mode: Phaser.Scale.FIT,
    // Center vertically and horizontally
    autoCenter: Phaser.Scale.CENTER_BOTH
  },
  canvas: gameCanvas,
  physics: {
    default: "arcade",
    arcade: {
      gravity: {
        y: speedDown
      },
      debug: false
    }
  },
  scene: [GameScene, ShareScene, EarnCoinsScene, UpgradeScene]
}

const game = new Phaser.Game(Config);
export default GameScene;



