import './style.css'
import Phaser from 'phaser'
import ShareScene from './shareScene';
import EarnCoinsScene from './earnCoinsScene';

const sizes = {
  width: 650,
  height: 1280
}
const speedDown = 100;
const coinsToDog = 1000;

const topSizeAnimation = 0.3;
let topReached = false;
const bottomSizeAnimation = 0.25;

const gameStartDiv = document.querySelector("#gameStartDiv");
const gameEndDiv = document.querySelector("#gameEndDiv");
const startButton = document.querySelector("#startButton");
var name = "";
var last_name = "";
var user_name = "";
var userId = "";
var apiUrl = "";
var apiUrlScore = "";
var apiUrlScoreM1000 = "";
var apiGetScore = "";
var apiGetDogCoins = "";
var apiUrlDogCoins = "";
let points = 0;
let dogsCoins = 0;

// Example of running a URL silently
function runUrlSilently(url) {
  try {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.text();
      })
      .then(data => {
        console.log('Success:', data); // You can log the response or handle it
      })
      .catch(error => {
        // Catch any network errors (like server unreachable, etc.)
        console.log('Handled silently. Fetch failed but no error will be shown.');
      });
  }
  catch (err) {
    console.log("err")
  }
}

async function getUserScore() {
  let score = 0;
  try {
    const response = await fetch(apiGetScore); // Wait for the API response
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.text(); // Wait for the response text to be retrieved
    console.log(data); // Output the response from the PHP file

    // Process the score and extract it from the response
    score = extractScoreFromResponse(data);
    if (score == null) {
      score = 0
    }
    console.log(`User's score is: ${score}`);

    return score; // Return the score after successful fetch
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return 0; // Return 0 in case of an error
  }
}


async function getUserDogCoins() {
  let coins = 0;
  try {
    const response = await fetch(apiGetDogCoins); // Wait for the API response
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.text(); // Wait for the response text to be retrieved
    console.log(data); // Output the response from the PHP file

    // Process the score and extract it from the response
    coins = extractScoreFromResponse(data);
    if (coins == null) {
      coins = 0
    }
    console.log(`User's dogCoins is: ${coins}`);

    return coins; // Return the score after successful fetch
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return 0; // Return 0 in case of an error
  }
}

// Helper function to extract score from PHP response
function extractScoreFromResponse(responseText) {
  // Assuming the PHP returns something like: "User '12345' has a score of: 50."
  const match = responseText.match(/score of: (\d+)/);
  return match ? parseInt(match[1], 10) : null; // Extract and return the score as an integer
}

// get parameters from URL and save them into variables userName and userId
function getQueryParams() {
  const params = {};
  const queryString = window.location.search;  // Get the part of the URL after "?"

  console.log("Query string:", queryString);  // Debugging: Check what the query string looks like

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);  // Create URLSearchParams object from query string

    // Iterate over each parameter and store it in the params object
    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
      console.log(`Parameter found - ${key}: ${value}`);  // Debugging: Log each key-value pair
    }
  } else {
    console.log("No query parameters found.");
  }
  userId = params["userID"]
  name = params["name"];
  last_name = params["last_name"]
  user_name = params["user_name"]

  if (user_name == null) {
    user_name = "not_available"
  }

  if (last_name == null) {
    last_name = "not_available"
  }

  apiUrl = "https://drdog.fleicom.com/updateUser.php?userID=" + userId + "&name=" + params["name"] + "&last_name=" + last_name + "&user_name=" + user_name;
  runUrlSilently(apiUrl);
  apiUrlScore = "https://drdog.fleicom.com/updateScore.php?userID=" + userId;
  apiGetScore = "https://drdog.fleicom.com/getScore.php?userID=" + userId;
  apiGetDogCoins = "https://drdog.fleicom.com/getDogCoins.php?userID=" + userId;
  apiUrlDogCoins = "https://drdog.fleicom.com/updateDogCoin.php?userID=" + userId
  apiUrlScoreM1000 = "https://drdog.fleicom.com/updateScoreM1000.php?userID=" + userId


  return params;
}


class BasicButton extends Phaser.GameObjects.Sprite {
  constructor(config) {

    //check if config contains a scene
    if (!config.scene) {
      console.log('missing scene');
      return;
    }
    //check if config contains a key
    if (!config.key) {
      console.log("missing key!");
      return;
    }
    //if there is no up property assume 0
    if (!config.up) {
      config.up = 0;
    }
    //if there is no down in config use up
    if (!config.down) {
      config.down = config.up;
    }
    //if there is no over in config use up
    if (!config.over) {
      config.over = config.up;
    }
    //call the constructor of the parent
    //set at 0,0 in case there is no x and y
    //in the config
    super(config.scene, 0, 0, config.key, config.up);

    //make a class level reference to the config
    this.config = config;


    //if there is an x assign it
    if (config.x) {
      this.x = config.x;
    }
    //if there is an x assign it
    if (config.y) {
      this.y = config.y;
    }

    if (config.width && config.height) {
      this.setDisplaySize(config.width, config.height);
    }
    //
    //add this to the scene
    config.scene.add.existing(this);
    //
    //make interactive and set listeners
    this.setInteractive();
    this.on('pointerdown', this.onDown, this);
    this.on('pointerup', this.onUp, this);
    this.on('pointerover', this.onOver, this);
    this.on('pointerout', this.onUp, this);
  }
  onDown() {
    this.setFrame(this.config.down);
  }
  onOver() {
    this.setFrame(this.config.over);
  }
  onUp() {
    this.setFrame(this.config.up);
  }
}

class GameScene extends Phaser.Scene {



  constructor() {
    super({
      key: 'GameScene'
    });
    this.player
    this.playerPressed
    this.cursor
    this.playerSpeed = speedDown + 50

    this.textScore;
    this.textDogCoins;
    this.textRemaining;

    this.username;
    this.dropSound;

    this.glow;
    this.emitter;
    this.dogCoinEmitter;
    this.button;
    this.buttonBack;
    this.buttonBack2;
    this.bitcoin;
    this.bitcoinLite;
    this.dogPressed;
    this.dogCoin;

    this.lightEffect;
    this.navigationHome;
    this.navigationShare;
    this.navigationGroups;

  }
  preload() {

    getQueryParams();
    this.load.image("back", "/assets/back.png");
    this.load.image("test", "/assets/dog.png");
    this.load.image("ball", "/assets/ball.png");
    this.load.image("bitcoin", "/assets/bitcoin.png");
    this.load.image("backbutton", "/assets/buttonback.png");
    this.load.image("dogPressed", "/assets/dogpressed.png");
    this.load.image("homenav", "/assets/homenav.png");
    this.load.image("sharenav", "/assets/sharenav.png");
    this.load.image("groupsnav", "/assets/groupsnav.png");
    this.load.image("lightEffect", "/assets/lighteffect.png");
    this.load.image("bitcoinLite", "/assets/coinlight.png");
    this.load.audio("drop", "/assets/sounds/drop.wav");
    this.load.image("dogCoin", "/assets/dogcoin.png");
    this.load.image("glow", "/assets/glow.png")
  }

  async create() {

    this.dropSound = this.sound.add("drop");
    this.add.image(0, 0, "back").setDisplaySize(sizes.width * 2, sizes.height * 2);
    this.player = this.physics.add.image(sizes.width / 2 - 200, sizes.height / 2 - 200, "test").setOrigin(0, 0);
    this.player.setInteractive();
    this.player.on('pointerdown', this.onDown, this);
    this.player.on('pointerup', this.onUp, this);
    this.tweens.add({
      targets: this.player,
      alpha: { from: 0, to: 1 },
      ease: 'Sine.InOut',
      duration: 5000,
      repeat: 0,
      yoyo: false
    });

    this.player.setDisplaySize(400, 400).setOffset(0, 0);
    this.player.setImmovable = true;
    this.player.body.allowGravity = false;
    this.player.setCollideWorldBounds(true);

    this.playerPressed = this.physics.add.image(sizes.width / 2 - 200, sizes.height / 2 - 200, "dogPressed").setOrigin(0, 0);
    this.playerPressed.setInteractive();
    this.playerPressed.on('pointerdown', this.onDown, this);
    this.playerPressed.on('pointerup', this.onUp, this);
    this.playerPressed.removeFromDisplayList();

    this.playerPressed.setDisplaySize(400, 400).setOffset(0, 0);
    this.playerPressed.setImmovable = true;
    this.playerPressed.body.allowGravity = false;
    this.playerPressed.setCollideWorldBounds(true);

    this.cursor = this.input.keyboard.createCursorKeys();


    this.buttonBack = this.add.image(sizes.width / 2, 130, "backbutton").setDisplaySize(400, 60);
    this.buttonBack2 = this.add.image(sizes.width / 2, 220, "backbutton").setDisplaySize(260, 90);
    this.bitcoin = this.physics.add.image(this.buttonBack.x - 160, this.buttonBack.y, "bitcoin").setDisplaySize(40, 40);

    //dog coin gloww

    this.dogCoin = this.physics.add.image(this.buttonBack2.x - 80, this.buttonBack2.y, "dogCoin").setDisplaySize(80, 80);
    this.glow = this.add.image(this.dogCoin.x, this.dogCoin.y, "glow").setOrigin(0.5, 0.5).setDisplaySize(200, 200).setVisible(false)
    this.glow.setBelow(this.dogCoin);

    this.bitcoin.setImmovable = true;
    this.bitcoin.body.allowGravity = false;
    this.dogCoin.setImmovable = true;
    this.dogCoin.body.allowGravity = false;
    this.dogCoin.setInteractive()
    this.dogCoin.on('pointerdown', this.onDogCoinDown, this);

    //bottom nav buttons
    this.navigationHome = this.add.image(30, sizes.height - 90, "homenav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationHome.setInteractive();
    this.navigationHome.on('pointerdown', () => this.transitionToScene('GameScene'));

    this.navigationGroups = this.add.image(sizes.width / 2 - 20, sizes.height - 90, "groupsnav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationGroups.setInteractive();
    this.navigationGroups.on('pointerdown', () => this.transitionToScene('EarnCoinsScene'));

    this.navigationShare = this.add.image(sizes.width - 100, sizes.height - 90, "sharenav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationShare.setInteractive();
    this.navigationShare.on('pointerdown', () => this.transitionToScene('ShareScene'));

    //light effect
    this.lightEffect = this.add.image(0, 40, "lightEffect").setOrigin(0, 0).setDisplaySize(sizes.width, 10);
    this.lightEffect = this.add.image(0, sizes.height - 130, "lightEffect").setOrigin(0, 0).setDisplaySize(sizes.width, 10);



    // Score text
    this.textRemaining = this.add.text(sizes.width / 2 - 130, 60, coinsToDog - parseInt(points) + " coins needed!", { font: "25px helvetica bold", fill: "#ffffff" });

    // Score text
    this.textScore = this.add.text(sizes.width / 2 - 20, 100, points, { font: "60px helvetica bold", fill: "#ffffff" });

    // text Dog
    this.textDogCoins = this.add.text(this.dogCoin.x + 60, this.dogCoin.y - 30, dogsCoins, { font: "60px helvetica bold", fill: "#ffffff" });

    // userName text
    var un = ""
    if (name != "NaN") {
      un = name;
    }
    if (last_name != "NaN" && last_name != "not_available") {
      un = un + " " + last_name;
    }
    this.username = this.add.text(10, 10, un, { font: "25px helvetica bold", fill: "#ffffff" });


    this.emitter = this.add.particles(100, 100, "bitcoinLite",
      {
        speed: 300,
        gravity: speedDown - 500,
        scale: { start: 0.9, end: 0 }, end: 0,
        duration: 300,
        lifespan: 1000,
        emitting: true,
        follow: this.player,

      })
    this.emitter.setPosition(this.player.x + 20, this.player.y - 280);

    //emitter on converting coins to dr. dog
    this.dogCoinEmitter = this.add.particles(100, 100, "dogCoin",
      {
        speed: 200,
        gravity: speedDown - 500,
        scale: { start: 0.3, end: 0 }, end: 0,
        duration: 300,
        lifespan: 800,
        emitting: true,

      })
    this.dogCoinEmitter.setPosition(this.dogCoin.x, this.dogCoin.y);


    window.Telegram.WebApp.expand()
    /*     BUTTON EXAMPLE
  this.button = new BasicButton({
    'scene': this,
    'key': 'bitcoin',
    'up': 0,
    'over': 0,
    'down': 0,
    'x': sizes.width / 4 - 20,
    'y': sizes.height - sizes.height / 9,
    'width': 100,
    'height': 100
  });
  */
    this.cameras.main.fadeIn(1000, 0, 0, 0);

    points = await getUserScore();
    dogsCoins = await getUserDogCoins();

  }
  update() {

    const { left, right } = this.cursor
    if (left.isDown) {
      this.player.setVelocityX(-this.playerSpeed - 50);
    }
    else if (right.isDown) {
      this.player.setVelocityX(this.playerSpeed + 50);
    }
    else {
      this.player.setVelocityX(0);
    }

    this.textScore.setText(points);





    this.textDogCoins.setText(dogsCoins);
    if (coinsToDog - points > 0) {
      this.textRemaining.setText(coinsToDog - points + " coins needed!")
    }
    else {
      this.textRemaining.setText("Convert coins to Dr. Dog!")
    }


    if (points >= 1000) {


      //glow rotation
      this.glow.angle++
      //dogCoin resizing animation
      this.resizingAnimation(this.dogCoin)

      //add glow
      this.tweens.add({
        targets: this.glow,
        alpha: { from: 0, to: 1 },
        ease: 'Sine.InOut',
        duration: 2000,
        repeat: 0,
        yoyo: false
      });

      this.glow.setVisible(true)
    }

  }

  resizingAnimation(image) {

    // Use a small value to check scale range (for floating-point precision)
    const precision = 0.0001;

    // Check if we should scale up or down based on the `topReached` status
    if (!topReached) {
      image.setScale(image.scale + 0.001);
      // If we reach or exceed the top size, toggle direction
      if (image.scale >= topSizeAnimation - precision) {
        topReached = true;
      }
    } else {
      image.setScale(image.scale - 0.0008);
      // If we reach or go below the bottom size, toggle direction
      if (image.scale <= bottomSizeAnimation + precision) {
        topReached = false;
      }
    }

  }

  //Convert coins to Dr Dog
  onDogCoinDown(sprite, pointer) {

    if (points >= coinsToDog) {
      // start emmiter effect
      this.dogCoinEmitter.start()

      //update drDog coins in database
      runUrlSilently(apiUrlDogCoins)

      //substract 1000 coins in database
      runUrlSilently(apiUrlScoreM1000)

      points = points - coinsToDog
      dogsCoins = dogsCoins + 1



      //hide glow
      this.tweens.add({
        targets: this.glow,
        alpha: { from: 1, to: 0 },
        ease: 'Sine.InOut',
        duration: 3000,
        repeat: 0,
        yoyo: false
      });
    }
  }

  onDown(sprite, pointer) {
    points = points + 1;

    this.player.removeFromDisplayList();
    this.playerPressed.addToDisplayList();
    this.emitter.start();
    runUrlSilently(apiUrlScore);
  }
  onUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }

  onEarnDown(sprite, pointer) {
    this.scene.start('EarnCoinsScene');
  }
  onEarnUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }

  onShareDown(sprite, pointer) {
    // Switch to ShareScene
    this.scene.start('ShareScene');
  }
  onShareUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }

  targetHit() {
    this.enemy.x = this.getRandomX();
    this.enemy.y = 0;
    points++;
    console.log(points);
    this.textScore.setText(this.points);
    this.dropSound.play();
    this.emitter.start();
  }

  getRandomX() {
    return Math.floor(Math.random() * sizes.width);
  }

  gameOver() {
    console.log("Game over!");
  }

  transitionToScene(targetScene) {
    this.cameras.main.fadeOut(300, 0, 0, 0);  // Fade out over 1 second (1000ms)

    this.time.delayedCall(300, () => {  // Wait for fade-out to complete
      this.scene.start(targetScene);   // Start the new scene
    });
  }
}

const Config = {


  type: Phaser.WEBGL,
  width: sizes.width,
  height: sizes.height,
  scale: {
    // Fit to window
    mode: Phaser.Scale.FIT,
    // Center vertically and horizontally
    autoCenter: Phaser.Scale.CENTER_BOTH
  },
  canvas: gameCanvas,
  physics: {
    default: "arcade",
    arcade: {
      gravity: {
        y: speedDown
      },
      debug: false
    }
  },
  scene: [GameScene, ShareScene, EarnCoinsScene]
}

const game = new Phaser.Game(Config);
export default GameScene;



