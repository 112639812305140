import Phaser from 'phaser';
import GameScene from './main';

const sizes = {
    width: 650,
    height: 1280
}

class ShareScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'ShareScene'
        });
        this.navigationHome;
        this.navigationShare;
        this.navigationGroups;
    }

    preload() {
        this.load.image("back", "/assets/back.png");
        this.load.image("homenav", "/assets/homenav.png");
        this.load.image("sharenav", "/assets/sharenav.png");
        this.load.image("groupsnav", "/assets/groupsnav.png");
    }

    create() {
        //add background
        this.add.image(0, 0, "back").setDisplaySize(sizes.width * 2, sizes.height * 2);

        //bottom nav buttons
        this.navBack = this.add.image(0, sizes.height - 100, "navback").setOrigin(0, 0).setDisplaySize(sizes.width, 100);
        this.navigationHome = this.add.image(30, sizes.height - 85, "homenav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationHome.setInteractive();
        this.navigationHome.on('pointerdown', () => this.transitionToScene('GameScene'));
        this.add.text(this.navigationHome.x - 15, this.navigationHome.y + this.navigationHome.displayHeight, "Exchange", { font: "20px helvetica bold", fill: "#9A9A9A" });
        5
        this.navigationUpgrades = this.add.image(sizes.width / 3 - 10, sizes.height - 85, "upgradesnav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationUpgrades.setInteractive();
        this.navigationUpgrades.on('pointerdown', () => this.transitionToScene('UpgradeScene'));
        this.add.text(this.navigationUpgrades.x - 15, this.navigationUpgrades.y + this.navigationUpgrades.displayHeight, "Upgrades", { font: "20px helvetica bold", fill: "#9A9A9A" });

        this.navigationReferral = this.add.image(sizes.width / 3 * 2 - 30, sizes.height - 85, "groupsnav").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationReferral.setInteractive();
        this.navigationReferral.on('pointerdown', () => this.transitionToScene('EarnCoinsScene'));
        this.add.text(this.navigationReferral.x - 15, this.navigationReferral.y + this.navigationReferral.displayHeight, "Referral", { font: "20px helvetica bold", fill: "#9A9A9A" });

        this.navigationEarn = this.add.image(sizes.width - 90, sizes.height - 85, "earnnavactive").setOrigin(0, 0).setDisplaySize(45, 45);
        this.navigationEarn.setInteractive();
        this.navigationEarn.on('pointerdown', () => this.transitionToScene('ShareScene'))
        this.add.text(this.navigationEarn.x - 20, this.navigationEarn.y + this.navigationEarn.displayHeight, "Earn more", { font: "20px helvetica bold", fill: "#9A9A9A" });

        //  this.navigationShare.on('pointerdown', () => this.transitionToScene('AnotherScene'));
        this.cameras.main.fadeIn(1000, 0, 0, 0);
        // Score text
        this.textScore = this.add.text(sizes.width / 2 - 200, sizes.height / 2 - 100, "Share Page", { font: "80px arial bold", fill: "#ffffff" });
        // Optional: Add text or additional effects in your scene
    }

    update(time, delta) {
        // Update logic here if needed
    }

    // Define the callback functions
    onDown() {
        this.scene.start('scene-game');
    }

    onUp() {
        console.log("Button released");
        // Add your logic for the button up event
    }

    onShareDown() {
        console.log("Share button pressed");
        // Add your logic for the share button down event
    }

    onShareUp() {
        console.log("Share button released");
        // Add your logic for the share button up event
    }

    // Function to handle scene transition with a fade effect
    transitionToScene(targetScene) {
        this.cameras.main.fadeOut(300, 0, 0, 0);  // Fade out over 1 second (1000ms)

        this.time.delayedCall(300, () => {  // Wait for fade-out to complete
            this.scene.start(targetScene);   // Start the new scene
        });
    }
}

export default ShareScene;
